<template>
  <div class="container">
    <ScrollNotice />
    <div class="head clearFix">
      <div class="content">
        <div class="tip">最新</div>
        <div class="title">
          <div class="post">{{info.position}}</div>
          <div class="price">{{info.price}}</div>
        </div>
        <div class="require">
          {{info.city}}
          <el-divider direction="vertical" />
          {{info.age}}
          <el-divider direction="vertical" />
          {{info.education}}
        </div>
        <div class="concat">
          <el-button>立即沟通1</el-button>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="body_left">
        <div class="name_avatar">
          <el-image :src="info.upic"/>
          <div class="name_wrap">
            <div class="name">{{info.uname}}</div>
            <div class="post">{{info.post}}</div>
          </div>
        </div>
        <div class="left_item">
          <div class="title">
            职位描述
            <el-divider />
          </div>
          <div class="content">
            <p class="sum">岗位职责</p>
            <p class="text gup">
              1、辅助公司软件的ui设计，提供良好的人机互动界面； <br>
              2、将公司核心算法视觉化，设计美观易用的操作界面； <br>
              3、参与公司软件产品研发，为研发部门提供必要的美学支持。
            </p>
            <p class="sum">任职要求</p>
            <p class="text">
              1、大专及以上学历，设计、视觉、人机交互等设计相关专业优先； <br>
              2、责任心强，沟通能力好； <br>
              3、人品端正，乐于学习。 <br>
              上班时间：周一至周五9:00-17:00，双休，不出差，不加班。
            </p>
          </div>
        </div>
        <div class="left_item">
          <div class="title">
            公司介绍
            <el-divider />
          </div>
          <div class="content">
            <p class="text">
              {{info.companyintroduction}}
            </p>
          </div>
        </div>
        <div class="left_item">
          <div class="title">
            工商信息
            <el-divider />
          </div>
          <div class="content">
            <p class="text">
              法定代表人：{{info.representative}} &nbsp;&nbsp;
              注册资本：{{info.capital}} &nbsp;&nbsp;
              成立日期：{{info.dateoin}}
            </p>
            <p class="text">企业类型：{{info.enterprisetype}}</p>
            <p class="text">经营状态：{{info.operating_status}}</p>
            <p class="text">注册地址：{{info.address}}</p>
            <p class="text">统一社会信用代码：{{info.code}}</p>
            <p class="text">经营范围：{{info.natureofbusiness}}</p>
          </div>
        </div>
      </div>
      <div class="body_right">
        <div class="title">
          公司环境
          <el-divider />
        </div>
        <el-image :src="info.pic" fit="cover" style="width: 239px;height: 137px;margin: 20px auto 40px"/>
        <div class="title">
          其他职位
          <el-divider />
        </div>
        <div class="list">
          <div class="list_item" v-for="i in 3" :key="i">
            <p class="title"><span class="job">{{info.position}}</span> <span class="price">{{info.price}}</span></p>
            <p class="tip">{{info.education}}</p>
            <el-image :src="info.pic" style="width: 50px;height: 50px" fit="cover"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"; 
  import ScrollNotice from '@/components/ScrollNotice'
  export default {
    name: 'Job',
    components: { ScrollNotice },
	
    data() {
      return {
        info: {
          post: '招聘专员',
          position: '骨科医生',
          price: '面议',
          city: '济南',
          age: '1-3年',
          //education: '研究生',
          education: '本科',
          name: '孟先生',
          representative: '孟玉林',
          capital: '300万',
          dateoin: '2020-10-13',
          enterprisetype: '有限责任公司(自然人独资)',
          operating_status: '在营',
          code: '91370112MA3U5JX71',
          natureofbusiness: '一般项目：软件开发；软件销售；知识产权服务；企业管理咨询；第二类以来' +
            '器械销售；第一类医疗器械销售；医用口罩零售；日用口罩(非医用)销售;电子烟雾化器(非' +
            '烟草制品、不含烟草成分)销售。(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)',
          address: '山东省济南市历城区东风街道二环东路3966号东环国际广场B座2205',
          avatar: require('@/assets/avatar/弘淑.jpg'),
          companyintroduction: '   山东康准信息科技有限公司主营业务智能化财务软件研发和股票软件研发，公司研发' +
            '的“股票持仓分析系统”等8项产品已获得国家著作权专利，研发完成正在测试的待申请专利产品还' +
            '有若干，公司计划未来两年时间研发出100套申请著作权专利的产品。',
          pic: require('@/assets/images/working.jpg')
        }
      }
    },
	created(){
		
		var query=this.$route.query;
		//this.id = query.id; 
		const params = new URLSearchParams();
		params.append('id', query.id); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getRecruitInfo',params)
		.then((response) => {  
			console.log(response.data.data);
			
			this.info = response.data.data;  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		   
	},
  }
</script>

<style scoped lang="scss">
  .container{
    .head{
      height: 306px;
      background-color: #353849;
      color: white;
      .content{
        width: 1200px;
        height: 216px;
        margin: auto;
        padding: 45px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      .tip{}
      .title{
        font-size: 32px;
        display: flex;
        flex-direction: row;
      .post{
        margin-right: 116px;
      }
      .price{
        color: $red;
        font-size: 28px;
      }
    }
    .require{
      font-size: 14px;
      .el-divider--vertical{
        height: 2px;
        width: 2px;
        vertical-align: baseline;
      }
    }
    .concat{
      .el-button{
        width: 125px;
        height: 45px;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
    }
    }
    .body{
      width: 1200px;
      height: 1064px;
      margin: 40px auto 140px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title{
        /*margin-bottom: 20px;*/
        font-weight: 600;
        font-size: 14px;
        .el-divider{
          width: 20px;
          height: 2px;
          background-color: $green;
          margin: 11px 0;
        }
      }
      .body_left{
        box-sizing: border-box;
        width: 887px;
        background-color: #fff;
        border-radius: 3px;
        padding: 26px 30px;
        .name_avatar{
          display: flex;
          flex-direction: row;
          margin-bottom: 36px;
          .el-image{
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin-right: 20px;
            border: 1px solid #a0a0a0;
          }
          .name_wrap{
            height: 40px;
            padding: 12px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .name{
              font-size: 14px;
            }
            .post{
              font-size: 12px;
              color: #888;
            }
          }
        }
        .left_item{
          .content{
            width: 90%;
            margin-bottom: 40px;
            margin-top: 20px;
            line-height: 28px;
            color: #808080;
            .sum{
              margin-bottom: 10px;
            }
            .text{
              font-size: 12px;
              &.gup{
                margin-bottom: 20px;
              }
            }
            .working{
              width: 239px;
              height: 137px;
            }
          }
        }
        /*margin-right: 20px;*/
      }
      .body_right{
        width: 293px;
        height: 100%;
        background-color: #fff;
        border-radius: 3px;
        padding: 28px;
        box-sizing: border-box;
        .list{
          .list_item{
            position: relative;
            width: 240px;
            height: 50px;
            margin: 0 auto;
            padding: 24px 0 20px;
            display: flex;
            border-bottom: 1px solid #eee;
            flex-direction: column;
            justify-content: space-between;
            .job{
              margin-right: 20px;
            }
            .price{
              color: $red;
            }
            .tip{
              color: #c2c2c2;
            }
            .el-image{
              position: absolute;
              top: 24px;
              right: 0;
            }
          }
        }
      }

    }
  }
  /deep/ .el-form-item__label-wrap .el-form-item__label{
    color: #9c9c9c;
  }
  /deep/ .el-form-item__content{
    padding-left: 14px!important;
  }
</style>
